<template>
  <div class="class-list">
    <el-dialog
      title="直播帮助"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
    >
      <div class="title_obs">OBS第三方推流</div>
      <div class="img_obs">
        <div>
          <img src="../../assets/live/obs_one.png" alt="" />
          <p>1.复制推流地址直播码</p>
        </div>
        <div>
          <img src="../../assets/live/obs_two.png" alt="" />
          <p>2.将信息粘贴到OBS</p>
        </div>
        <div>
          <img src="../../assets/live/obs_three.png" alt="" />
          <p>3.点击OBS开播推流</p>
        </div>
      </div>
      <div class="down_obs">
        <div>
          OBS下载地址：<span @click="windowsOBS">Windows点击下载</span
          ><span @click="macOBS">MacOS点击下载</span>
        </div>
        <div class="help_text">使用手册：<span @click="download">点击下载</span></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改回放"
      :visible.sync="dialogShowVisible"
      width="560px"
      :close-on-click-modal="false"
      top="40vh"
      class="dialogChangeVideo"
      @close="handleCancelLiveVideo"
    >
      <div class="dialogMain">
        <el-form ref="formDailog" :model="formDailog" label-width="135px" :rules="rules">
          <el-form-item label="素材第三方平台：" prop="livePlatform">
            <el-select
              v-model="formDailog.livePlatform"
              placeholder="请选择"
              class="selectInput"
              disabled
            >
              <el-option label="腾讯云" value="5"> </el-option>
              <!-- <el-option label="阿里云" value="2"> </el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="视频ID：" prop="vid">
            <el-input
              v-model="formDailog.vid"
              placeholder="请输入"
              class="selectInput"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="footerDialog">
        <el-button @click="handleCancelLiveVideo">取 消</el-button>
        <el-button type="primary" @click="handleUpdateLiveVideo('formDailog')">确 定</el-button>
      </div>
    </el-dialog>
    <table-list
      :loading="loading"
      :search-form="searchForm"
      :data="list"
      :title="tableTitle"
      :options="{ selection: true, operatesWidth: 250, fixScrollTable: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @selectionChange="handleSelectionChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <goLive ref="goLive"></goLive>
    <EditLiveVideo ref="EditLiveVideo"></EditLiveVideo>
  </div>
</template>
<script>
//表格标题
const tableTitle = '直播管理'
const btns = _this => [
  {
    label: '直播帮助',
    type: 'primary',
    method: _this.handleHelp,
  },
  {
    label: '创建直播',
    type: 'primary',
    method: _this.handleAddLive,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = [
  {
    label: '直播名称',
    prop: 'liveName',
  },
  {
    label: '直播方式',
    prop: 'liveType',
    type: 'select',
    children: [
      { value: 0, label: 'PC' },
      // { value: 1, label: 'APP' },
      { value: 2, label: '小程序' },
    ],
  },
  {
    label: '启用状态',
    prop: 'publishStatus',
    type: 'select',
    children: [
      { value: 0, label: '未发布' },
      { value: 1, label: '已发布' },
    ],
  },
  {
    label: '直播状态',
    prop: 'liveStatus',
    type: 'select',
    children: [
      { value: 0, label: '编辑中' },
      { value: 1, label: '待开始' },
      { value: 2, label: '直播中' },
      { value: 3, label: '已结束' },
    ],
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'liveName',
    label: '直播名称',
    minWidth: 150,
    render(h, { row }) {
      return h(
        'div',
        {
          class: { liveName: row.liveName.length > 18 },
        },
        row.liveName,
      )
    },
  },
  {
    prop: 'liveType',
    label: '直播方式',
    formatter: row => {
      if (row.liveType == 0) {
        return 'PC'
      } else if (row.liveType == 1) {
        return 'App'
      } else {
        return '小程序'
      }
    },
    minWidth: 100,
  },
  {
    prop: 'liveStatus',
    label: '直播状态',
    formatter: row => {
      if (row.liveStatus == 0) {
        return '编辑中'
      } else if (row.liveStatus == 1) {
        return '待开始'
      } else if (row.liveStatus == 2) {
        return '直播中'
      } else {
        return '已结束'
      }
    },
    minWidth: 100,
  },
  {
    prop: 'startTimeShow',
    label: '开播时间',
    sortable: 'sortable',
    minWidth: 150,
  },
  {
    prop: 'endTimeShow',
    label: '结束时间',
    sortable: 'sortable',
    minWidth: 150,
  },
  {
    prop: 'vid',
    label: '视频ID',
    minWidth: 220,
    twoLines: true,
  },
  {
    prop: 'sourceType',
    label: '视频源',
    minWidth: 100,
    formatter: row => {
      if (row.livePlatform == 5) {
        return '腾讯云'
      } else if (row.livePlatform == 0) {
        return '-'
      }
    },
  },
  {
    prop: 'publishStatus',
    label: '启用状态',
    minWidth: 80,
    render: (h, { row }) => {
      return [
        h('el-switch', {
          //   props: { value: row.publishStatus },
          props: {
            value: row.publishStatus,
            activeValue: 1,
            inactiveValue: 0,
            disabled: row.liveStatus == 3 || row.liveStatus == 2,
          },
          on: {
            change: async v => {
              await _this.handleOpen(v, row)
            },
          },
        }),
      ]
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    isShow: row => row.liveStatus == 0,
    method: _this.handleEdit,
  },
  {
    label: '详情',
    isShow: row => row.liveStatus == 3 || row.liveStatus == 2 || row.liveStatus == 1,
    method: _this.handleDetail,
  },

  {
    label: '去直播',
    isShow: row => row.liveStatus == 1 || row.liveStatus == 2,
    method: _this.handleGoLive,
  },
  {
    label: '结束直播',
    isShow: row => row.liveStatus == 1 || row.liveStatus == 2,
    method: _this.handleCloseLive,
  },
  {
    label: '观看回放',
    isShow: row => row.liveStatus == 3,
    method: _this.handleLook,
  },
  {
    label: '修改回放',
    isShow: row => row.liveStatus == 3,
    // method: _this.handleChangeVideo,
    method: _this.handleEditNewPage,
  },
]
import goLive from './components/goLive'
import TableList from '@/components/TableList'
import EditLiveVideo from './components/editLiveVideo'
import { liveList, updateStatus, deleteLive, closeLive, updateLiveVideo } from '@/api/live'
import to from 'await-to'
export default {
  components: {
    TableList,
    goLive,
    EditLiveVideo,
  },
  data() {
    return {
      searchForm,
      columns,
      operates,
      tableTitle,
      btns,
      loading: false,
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      selectLive: [],
      queryParams: {},
      dialogVisible: false,
      dialogShowVisible: false,
      formDailog: {
        liveId: '',
        vid: '',
        livePlatform: '5',
      },
      rules: {
        livePlatform: [{ required: true, message: '请选择素材第三方平台', trigger: 'change' }],
        vid: [{ required: true, message: '请输入视频Id', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.liveListData()
  },
  methods: {
    handleEditNewPage(row) {
      if (row.liveId) {
        this.$refs.EditLiveVideo.show(row.liveId)
      }
    },
    async handleUpdateLiveVideo(form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          const [, err] = await to(updateLiveVideo({ ...this.formDailog }))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: '修改成功' })
          this.dialogShowVisible = false
          this.liveListData()
        }
      })
    },
    async liveListData() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(liveList({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    async handleOpen(v, row) {
      const [, err] = await to(updateStatus({ liveId: row.liveId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '修改成功' })
      row.publishStatus = row.publishStatus == 1 ? 0 : 1
      this.liveListData()
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.liveListData()
    },
    handleGoLive(row) {
      this.$refs.goLive.show(row.liveId)
    },
    handleLook(row) {
      this.$router.push({
        path: '/live/livePlayback',
        query: {
          liveId: row.liveId,
          liveName: row.liveName,
          teacherName: row.teacherName,
          livePic: row.livePic,
          videoId: row.vid,
          videoPlatform: row.livePlatform,
        },
      })
    },
    handleAddLive() {
      this.$router.push('live/add')
    },
    // 编辑
    handleEdit(row) {
      this.$router.push(`live/edit/${row.liveId}`)
    },
    handleCloseLive(row) {
      this.$confirm('确认结束直播吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(closeLive({ liveId: row.liveId }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('结束成功')
        this.liveListData()
      })
    },
    // 删除
    handleDelete() {
      if (!this.selectLive.length) return this.$message.warning('请先选择要删除的直播')
      let liveId = []
      this.selectLive.forEach(v => {
        liveId.push(v.liveId)
      })
      this.$confirm('确认删除资源吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(deleteLive({ liveId: liveId }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.liveListData()
      })
    },
    handleHelp() {
      this.dialogVisible = true
    },
    // 多选
    handleSelectionChange(val) {
      this.selectLive = val
    },
    async handleDetail(row) {
      this.$router.push({ path: '/live/liveDetail', query: { liveId: row.liveId } })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.liveListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.liveListData()
    },
    handleChangeVideo(row) {
      this.dialogShowVisible = true
      this.formDailog.liveId = row.liveId
      if (row.vid) {
        this.formDailog.vid = row.vid
      }
      if (row.livePlatform) {
        this.formDailog.livePlatform = row.livePlatform + ''
      }
    },
    handleCancelLiveVideo() {
      this.dialogShowVisible = false
      this.formDailog.liveId = ''
      this.formDailog.vid = ''
      this.formDailog.livePlatform = ''
      this.$refs['formDailog'].resetFields()
    },
    windowsOBS() {
      window.open(
        'https://ironge-pro.obs.cn-north-4.myhuaweicloud.com/frontend/ironge-om-admin/obs/windows/OBSStudio26.1.1.exe',
      )
    },
    macOBS() {
      window.open(
        'https://ironge-pro.obs.cn-north-4.myhuaweicloud.com/frontend/ironge-om-admin/obs/mac/obs-mac-27.0.1.dmg',
      )
    },
    download() {
      window.open(
        'https://ironge-pro.obs.cn-north-4.myhuaweicloud.com/frontend/ironge-om-admin/obs/file/%E7%9B%B4%E6%92%AD%E8%AF%B4%E6%98%8E%E6%89%8B%E5%86%8C.docx',
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.help {
  position: absolute;
  left: 79.5%;
  top: 23.7%;
  width: 85px;
  height: 30px;
  line-height: 30px;
  display: flex;
  font-size: 16px;
  color: #ff7b33;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    display: block;
    margin: 8px 3px 0 0;
  }
}
.class-list {
  ::v-deep .liveName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  tr {
    &:last-of-type {
      width: 300px;
    }
  }
  ::v-deep.operate-group {
    span {
      margin-right: 15px;
    }
  }
}
.title_obs {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #303133;
}
.img_obs {
  display: flex;
  padding: 20px 60px;
}
img {
  width: 200px;
  height: 27px;
  display: block;
  margin-right: 25px;
}
p {
  width: 200px;
  text-align: center;
  font-size: 16px;
  color: #303133;
  margin: 20px 0;
}
.down_obs {
  padding: 0 60px;
  display: flex;
  font-size: 14px;
  color: #303133;
  span {
    color: #ff7b33;
    cursor: pointer;
    margin-right: 15px;
  }
}
.help_text {
  margin-left: 100px;
}
.dialogChangeVideo {
  ::v-deep .el-dialog {
    .el-dialog__footer {
      padding: 10px;
      .el-button {
        width: 65px;
        height: 32px;
        border-radius: 2px 2px 2px 2px;
      }
    }
  }
  .selectInput {
    width: 380px;
    height: 32px;
    ::v-deep .el-input__inner {
      height: 32px;
    }
  }
}
</style>
