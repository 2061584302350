<template>
  <div>
    <el-dialog
      title="开始直播"
      :visible.sync="dialogFormVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="OBSWarp">
        <div class="title">OBS第三方推流</div>
        <div class="step">
          <div class="stepItem">
            <div><img src="../../../assets/live/step1.png" alt="" /></div>
            <div class="text">复制推流地址直播码</div>
          </div>
          <div class="stepItem">
            <div><img src="../../../assets/live/step2.png" alt="" /></div>
            <div class="text">将信息粘贴到OBS</div>
          </div>
          <div class="stepItem">
            <div><img src="../../../assets/live/step3.png" alt="" /></div>
            <div class="text">点击OBS开播推流</div>
          </div>
        </div>
      </div>
      <div class="copyWrap">
        <div class="copyContent">
          <div>推流地址：</div>
          <div
            v-clipboard:copy="linkOBS"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            class="copy"
          >
            <p :title="linkOBS">{{ linkOBS }}</p>
            <div class="btn">
              复制
            </div>
          </div>
        </div>
        <div class="copyContent">
          <div>直播秘钥：</div>
          <div
            v-clipboard:copy="linkKey"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            class="copy"
          >
            <p :title="linkKey">{{ linkKey }}</p>
            <div class="btn">
              复制
            </div>
          </div>
        </div>
        <div class="download">
          <div>
            OBS下载地址：
            <span class="textBtn" @click="windowsOBS">Windows点击下载</span>
            <span class="textBtn ml" @click="macOBS">MacOS点击下载</span>
          </div>
          <div class="explain">
            使用说明：
            <span class="textBtn" @click="download">点击下载</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { goLive } from '@/api/live'
import to from 'await-to'
export default {
  data() {
    return {
      dialogFormVisible: false,
      linkOBS: '',
      linkKey: '',
    }
  },
  created() {},
  methods: {
    show(liveId) {
      this.dialogFormVisible = true
      this.goLiveData(liveId)
    },
    async goLiveData(liveId) {
      const [res, err] = await to(goLive({ liveId: liveId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.linkOBS = res.data.pushUrl
      this.linkKey = res.data.auth
    },
    windowsOBS() {
      window.open(
        'https://ironge-pro.obs.cn-north-4.myhuaweicloud.com/frontend/ironge-om-admin/obs/windows/OBSStudio26.1.1.exe',
      )
    },
    macOBS() {
      window.open(
        'https://ironge-pro.obs.cn-north-4.myhuaweicloud.com/frontend/ironge-om-admin/obs/mac/obs-mac-27.0.1.dmg',
      )
    },
    download() {
      window.open(
        'https://ironge-pro.obs.cn-north-4.myhuaweicloud.com/frontend/ironge-om-admin/obs/file/%E7%9B%B4%E6%92%AD%E8%AF%B4%E6%98%8E%E6%89%8B%E5%86%8C.docx',
      )
    },
    onCopySuccess() {
      this.$message.success('复制成功')
    },
    onCopyError() {
      this.$message.error('复制失败')
    },
  },
}
</script>

<style scoped lang="scss">
.OBSWarp {
  margin-bottom: 70px;
  .title {
    font-size: 18px;
    color: #333;
    text-align: center;
  }
}
.step {
  display: flex;
  margin-top: 25px;
  .stepItem {
    width: 33.33%;
    padding: 0 5px;
    box-sizing: border-box;
    text-align: center;
    .text {
      font-size: 14px;
    }
    img {
      width: 100%;
    }
  }
}

.copyWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .copyContent {
    display: flex;
    align-items: center;
    margin: 10px 0;
    .copy {
      width: 450px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #ff7b33;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      p {
        flex: 1;
        overflow: hidden;
        @extend %one-line-text;
        padding: 0 4px;
      }
      .btn {
        cursor: pointer;
        width: 120px;
        height: 40px;
        background: #ff7b33;
        font-size: 14px;
        font-weight: Normal;
        color: #ffffff;
        text-align: center;
      }
    }
  }
}
.download {
  font-size: 14px;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 40px;
  display: flex;
  .explain {
    margin-left: 40px;
  }
  .textBtn {
    color: #ff7b33;
    cursor: pointer;
  }
}
.ml {
  margin-left: 20px;
}
</style>
