<template>
  <div class="liveVideo">
    <el-dialog
      title="修改回放"
      :visible.sync="dialogFormVisible"
      width="800px"
      :close-on-click-modal="false"
    >
      <div class="header">
        <el-button type="primary" @click="handleVideoAdd">增加回放</el-button>
      </div>
      <el-table
        ref="sectionTable"
        v-loading="loading"
        :data="liveVedioList"
        style="width: 100%;margin-bottom: 20px;"
        max-height="500px"
        row-key="id"
        :row-class-name="tableClassName"
        border
      >
        <el-table-column prop="name" label="回放名称" width="400">
          <template slot-scope="scope">
            {{ scope.row.name ? scope.row.name : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="enable" label="启用/禁用" width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.enable"
              :active-value="1"
              :inactive-value="2"
              @change="handleChangeEnable(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="259">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleChangeBackName(scope.row)">
              编辑
            </el-button>
            <el-button type="text" size="small" @click="handleDeleteBackVedio(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-loading="loading"
      title="增加回放"
      :visible.sync="Visible"
      width="600px"
      top="20vh"
      :before-close="cancelBackVedioFn"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        style="padding-top: 20px;"
      >
        <el-form-item label="回放名称：" prop="name">
          <el-input
            v-model.trim="form.name"
            placeholder="请输入回放名称"
            class="inputClass"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="回放内容：">
          <template>
            <el-upload
              ref="videoUpload"
              action=""
              :before-upload="beforeUpload"
              :file-list="fileList"
              :limit="1"
              accept=".mp4,.Mp4"
              :on-change="handleFileChange"
              :on-remove="handleFileChange"
              :http-request="handleVideoUpload"
            >
              <el-button>选择上传素材</el-button>
              <p slot="tip" class="tip">
                文件最大不能超过4GB，格式支持MP4
              </p>
            </el-upload>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelBackVedioFn">取 消</el-button>
        <el-button type="primary" @click="addBackVedioFn">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑回放"
      :visible.sync="editVisible"
      width="500px"
      top="20vh"
      :close-on-click-modal="false"
      :before-close="handleCloseDialog"
    >
      <div class="main">
        <el-input
          v-model.trim="backVedioTitle"
          placeholder="请输入回放名称"
          class="inputClass"
          clearable
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="handleSubmitFn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  liveVedioList,
  sortVedio,
  tencentUploadSignature,
  addBackVedio,
  renameBackVedio,
  backVedioDelete,
  backVedioEnable,
} from '@/api/live'
import to from 'await-to'
import Sortable from 'sortablejs'
import TcVod from 'vod-js-sdk-v6'
let sortTable
export default {
  data() {
    return {
      dialogFormVisible: false,
      liveId: '',
      loading: false,
      liveVedioList: [],
      Visible: false,
      form: {},
      rules: {
        name: [{ required: true, message: '请输入回放名称', trigger: 'blur' }],
      },
      fileList: [],
      editVisible: false,
      backVedioTitle: '',
      currentQuery: {},
    }
  },
  methods: {
    show(id) {
      this.liveId = id
      this.dialogFormVisible = true
      this.liveListData(id)
    },
    async liveListData(id) {
      this.loading = true
      const [res, err] = await to(liveVedioList({ liveId: id }))
      this.loading = false
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.liveVedioList = res.data
      this.rowDrop()
    },
    tableClassName({ row }) {
      return 'id=' + row.id
    },
    rowDrop() {
      const tbody = this.$refs.sectionTable.$el.querySelectorAll(
        '.el-table__body-wrapper > table > tbody',
      )[0]
      sortTable = Sortable.create(tbody, {
        animation: 300,
        fallbackOnBody: true,
        dataIdAttr: 'class',
        onEnd: evt => {
          console.log(sortTable)
          if (evt.oldIndex == evt.newIndex) return
          const currRow = this.liveVedioList.splice(evt.oldIndex, 1)[0]
          this.liveVedioList.splice(evt.newIndex, 0, currRow)
          this.$nextTick(() => {
            let newArry = []
            this.liveVedioList.forEach((item, index) => {
              item.sort = index + 1
              let object = {}
              object.sort = item.sort
              object.playBackClipId = item.id
              newArry.push(object)
            })
            this.sortBackVideo(newArry)
          })
          console.log(this.liveVedioList, '排序后的回放')
        },
      })
    },
    async sortBackVideo(sortedIdList) {
      this.loading = true
      const [, err] = await to(sortVedio({ sortList: sortedIdList }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.liveListData(this.liveId)
    },
    handleVideoAdd() {
      this.Visible = true
    },
    async getTencentSignature() {
      const [res, err] = await to(tencentUploadSignature({}))
      if (err) return this.$message.warning(err.msg)
      return res.data
    },
    tencentUpload(mediaFile) {
      // 通过 import 引入的话，new TcVod(opts) 即可
      // new TcVod.default(opts) 是 script 引入 的用法
      const tcVod = new TcVod({
        getSignature: this.getTencentSignature, // 前文中所述的获取上传签名的函数
      })
      const uploader = tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      })
      uploader.on('media_progress', info => {
        console.log(`output->info`, info.percent * 100)
      })
      uploader
        .done()
        .then(doneResult => {
          // deal with doneResult
          this.loading = false
          console.log(`output->doneResult`, doneResult)
          this.handleUploadSuccess(doneResult)
        })
        .catch(err => {
          // deal with error
          this.loading = false
          this.$message.warning(err)
          console.log(`output->err`, err)
        })
    },
    async handleVideoUpload(file) {
      console.log(`output->file`, file)
      this.loading = true
      this.tencentUpload(file.file)
    },
    async handleUploadSuccess(response) {
      console.log(`output->'成功'`, '成功', response)
      this.form.vid = response.fileId
      this.form.videoPlatform = 5
      this.$message({ message: '导入成功', type: 'success' })
    },
    beforeUpload(file) {
      const isMP4 = file.type === 'video/mp4'
      if (!isMP4) {
        this.$message.error('上传视频只能是 MP4 格式!')
      }
      return isMP4
    },
    handleFileChange(file, fileList) {
      const isMP4 = file.raw.type === 'video/mp4'
      if (!isMP4) {
        this.$message.error('上传视频只能是 MP4 格式!')
      }
      this.fileList = []
      if (!isMP4) return
      this.fileList = fileList
      this.form.vid = ''
      this.form.videoPlatform = ''
    },
    async addBackVedioFn() {
      if (!this.fileList.length) {
        return this.$message.warning('请上传回放')
      }
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          const [, err] = await to(addBackVedio({ ...this.form, liveId: this.liveId }))
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('添加成功')
          this.form = {}
          this.fileList = []
          this.Visible = false
          this.liveListData(this.liveId)
        }
      })
    },
    cancelBackVedioFn() {
      this.form = {}
      this.fileList = []
      this.Visible = false
    },
    handleChangeBackName(row) {
      console.log(row)
      this.editVisible = true
      this.currentQuery = row
      if (row.name) {
        this.backVedioTitle = row.name
      }
    },
    async handleSubmitFn() {
      if (!this.backVedioTitle) return this.$message.warning('请输入回放名称')
      this.loading = true
      const [, err] = await to(
        renameBackVedio({ name: this.backVedioTitle, playBackClipId: this.currentQuery.id }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('编辑成功')
      this.currentQuery = {}
      this.backVedioTitle = ''
      this.editVisible = false
      this.liveListData(this.liveId)
    },
    handleCloseDialog() {
      this.currentQuery = {}
      this.backVedioTitle = ''
      this.editVisible = false
    },
    async handleDeleteBackVedio(row) {
      this.$confirm('您确定要删除吗？删除后将不可恢复', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let arry = [row.id]
        const [, err] = await to(backVedioDelete({ playBackClipIdList: arry }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.liveListData(this.liveId)
      })
    },
    async handleChangeEnable(v) {
      const [, err] = await to(backVedioEnable({ playBackClipId: v.id, enable: v.enable }))
      if (err) return this.$message.warning(err.msg)
      let text = ''
      if (v.enable == 1) {
        text = '启用成功'
      } else if (v.enable == 2) {
        text = '禁用成功'
      }
      this.$message.success(text)
      this.liveListData(this.liveId)
    },
  },
}
</script>
<style lang="scss" scoped>
.liveVideo {
  ::v-deep .el-dialog {
    .header {
      margin-bottom: 20px;
    }
    .inputClass {
      width: 320px;
    }
    .main {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
